:root {
    --lGray: #d7dce4;
    --GrayPlus: #989ca1;
    --verySmall: 75%;
    --veryVerySmall: 70%;
    --smallFont: 70%;
    --mediumFont: 80%;
    --regularFont: 100%;
    --MbigFont: 110%;
    --bigFont: 120%;
    --veryBigFont: 140%;
    --huge: 160%;
    --anak: 200%;
    --anakMeod: 240%;
    --anakMeodMeod: 300%;
    --swiper-theme-color: #fec000 !important;
}

.onlyOnDeskTop {
    display: none;
}

.onlyOnMobile {
    display: block;
}

body {
    margin: unset !important;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.noPadding {
    padding: unset !important;
}

.noMargin {
    margin: unset !important;
}

.fullRow {
    width: 100%;
}

.overflowHid {
    overflow: hidden;
}

.english {
    font-family: "Arial";
    font-size: 80%;
    direction: ltr;
}

.centerText {
    text-align: center;
}

.bigLineHeight {
    line-height: 1.2rem;
    margin-bottom: 15px;
}

.bold {
    font-weight: bold;
}

.verySmall {
    font-size: var(--verySmall);
}

.veryVerySmall {
    font-size: var(--veryVerySmall);
}

.vSmall {
    font-size: var(--smallFont);
}

.mediumst {
    font-size: var(--mediumFont);
}

.regular {
    font-size: var(--regularFont);
}

.MbigFont {
    font-size: var(--MbigFont);
}

.big {
    font-size: var(--bigFont);
}

.veryBig {
    font-size: var(--veryBigFont);
}

.huge {
    font-size: var(--huge);
}

.anak {
    font-size: var(--anak);
}

.anakMeod {
    font-size: var(--anakMeod);
}

.margTop {
    margin-top: 5px;
}


.spacePad {
    padding-top: 5px;
    padding-bottom: 5px;
}

.spacePadSmall {
    padding-top: 2px;
    padding-bottom: 2px;
}

.spaceMargTopBig {
    margin-top: 25px;
}
.spaceMargTopVBig {
    margin-top: 50px;
}

.spacePadBig {
    padding-top: 10px;
    padding-bottom: 10px;
}

.spaceTop {
    padding-top: 5px;
}

.spaceTopBig {
    padding-top: 10px;
}

.spaceButtom {
    padding-bottom: 5px;
}

.spaceButtomBig {
    padding-bottom: 10px;
}

.grayText {
    color: var(--GrayPlus);
}

.spaceRight {
    padding-right: 5px;
}

.spaceLeft {
    padding-left: 5px;
}

.spaceLeftLong {
    padding-left: 15px;
}

.spaceRightLong {
    padding-right: 15px;
}

.spaceRightVeryLong {
    padding-right: 35px;
}

.wrapPadding {
    /* padding: 2px; */
    padding-bottom: 15px;
}

.onRight {
    float: right;
}

.onLeft {
    float: left;
}

.centerMe {
    margin: 0 auto;
}

p {
    margin-block-start: 0.5px;
    margin-block-end: 0.5px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

a {
    text-decoration: none;
    color: black;
}

a:visited {
    color: black;
}

textarea {
    font-family: var(--MyFont);
}

.SinglePageContent a,
.SinglePageContent p,
.SinglePageContent span,
.SinglePageContent h1,
.SinglePageContent h2,
.SinglePageContent ul,
.SinglePageContent li {
    font-family: var(--myFont) !important;
}

.flexMe {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.pointing:hover {
    cursor: pointer;
}

.setTheTop {
    margin-top: -15px;
}

.tdmin10 {
    width: 15%;
    vertical-align: top;
}

.showMe {
    display: block;
}

.hideMe {
    display: none;
}

.fullWidth {
    width: 100%;
    overflow: hidden;
}

.overFlHid {
    overflow: hidden;
}

.halfFromLeft {
    width: 50%;
    float: left;
}

.halfFromRight {
    width: 50%;
    float: Right;
}

.headORdTxt::before {
    content: " | ";
}

span.Heads {
    width: 100%;
    display: block;
    line-height: 25px;
    font-weight: bold;

}

.SinglePage {
    min-height: 200px;
    margin: unset;
}

.ContactPage {
    min-height: 200px;
    margin: unset;
    margin-bottom: 60px;
}

.imInOrdBtn {
    font-weight: normal;
    display: table;
    float: left;
    border: 1px solid var(--lGray);
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
    font-size: 70%;
}

.ContCall {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
}

.ContactsData {
    margin: 0 auto;
    width: 70%;
    padding-top: 50px;
}

.contWrap {
    margin-bottom: 10px;
}

.ContactsData label {
    margin-top: 10px;
}

.contactGo {
    max-width: 100px;
    background-color: black;
    color: white;
    border: none;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 20px;
    margin-top: 10px;
}

.ContactsData input, .ContactsData textarea {
    border: none;
    border-bottom: 1px solid black;
}

.singlePagePad {
    padding-top: 55px;
}

.SinglePageContent {
    padding-top: 25px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    text-align: right;

}

.ContactPageContent {
    padding-top: 25px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    text-align: center;

}


span.headORdTxt {
    line-height: 10px;

}

.headORdLine {
    line-height: 100%;
    padding-top: 5px;
    font-size: 14px;
    max-width: 70%;
}

.blackRadio {
    color: black !important;
    padding: 6px;
}

.blackRadiolbl span,
.blackRadiolbl input {
    font-size: 15px;
}

.spacerMastBtn, .spacerfavBtn {
    opacity: 0;
}

.MyOrdersLst.historys {
    /* max-height: 400px; */
    overflow-y: auto;
}

.stepper {
    position: fixed;
    background-color: white;
    z-index: 5;
    right: 5px;
    top: 90px;
}


.gamaFrame {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.divDescriptition {
    padding-right: 15px;
}

.stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    width: 0.7em !important;
    height: 0.6em !important;
}

.stepper .css-1pe7n21-MuiStepConnector-root {
    margin-left: 35% !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 4px !important;
}


.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: unset !important;
}

.sectionBlock a {
    color: white;
    text-decoration: underline;
}