:root {

    --swiper-pagination-color: white;
}

body {
    background-color: black;
}

/***************  MASTER ***************/
/***************  topStrip ***************/
.insideWrap {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1250px;
    height: 105px;

}

.sectionBlock {
    width: 80%;
    height: 80%;
    top: 120px;
    left: 10%;
    position: absolute;
    direction: rtl;
    display: table;

}

.sectionBlockWide {
    width: 90%;
    height: 80%;
    top: 120px;
    left: 5%;
    position: absolute;
}

.topStrip {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 9998;
    width: 100%;
    direction: rtl;
    height: 105px;
    line-height: 105px;
    position: fixed;
    box-shadow: 0px 0px 2px rgb(255 255 255 / 80%);
    right: 0;

}

.swiper-container {
    background: black;
    width: 100%;
    height: 100%;

}


.swiper-wrapper {
    width: 100%;
    height: 100%;

}

.HPTopImagesLst {
    position: relative;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: black;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

}

.swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    background: white;
    opacity: .4;
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 12px 0;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    width: 15px;
    height: 15px;
    position: relative;
    border: 3px solid white;
    background: rgba(255, 255, 255, 0.4);
    left: -3px;


}

.activeMnu span {
    border-bottom: 1px solid lightgray;
}

.activeMnu span::after {
    border-bottom: 1px solid blue;
}

.swiper-slide-active {
    /* -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp; */
}

.swiper-slide-prev {
    /* -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown; */

}

.swiper-slide-next {}

.fullBkImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.mnusWrap {
    width: 90%;
    color: var(--lGray);
}

.menuStrip {
    width: 85%;
}

.menuStrip p {
    margin: unset;
}

.Master {
    background-color: black;

}

.swipGallItm {
    display: none;
}

.active-gal {}

.smallGal {
    height: 100%;
    overflow: auto;
    padding: 10px;
    display: none;
}

.smallGalImg {
    height: calc(20% - 20xp);
    width: calc(20% - 20px);
    float: left;
    margin: 10px;


}

.HPTopSwiper {}

.gallSwipepop {
    z-index: 10000 !important;
}

.gallSwipepop .MuiPaper-root {
    max-width: unset !important;
    max-height: unset !important;
    margin: 0;
    background-color: #0000007d;
    width: 100%;
    height: 100%;
}

.gallSwipepop .MuiDialogContent-root {
    height: 100%;
    padding: 0 2%;

}

.gallSwipepop .HPTopImages {
    height: 100%;
}

.gallSwipepop .MuiTypography-root {
    height: 100%;
}

.gallSwipepop .HPTopImage {
    width: 100%;
    /* height: 100%; */
}

.gallSwipepop .HPTImgWrap {
    width: 95%;
}

.smallGalImg:hover {
    /* height: calc(18% - 20xp);
    width: calc(18% - 20px);
    margin: 10px; */
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    transition: scale3d 1s;


}

.smallGalImg img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}


.GallBlock {
    /* width: 100%; */
    background-position-x: 0;
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    position: relative;
    z-index: 1600;
    transition: width 2s;
}

.rotation-wrapper-outer {
    display: table;
}

.rotation-wrapper-inner {
    padding: 0% 10%;
    height: 0;
}

.sectionrotate {
    display: block;
    transform-origin: top left;
    /* transform: rotate(-90deg) translate(-100%); */
    transform: rotate(90deg) translate(0, -100%);
    /* margin-top: -5%; */
    white-space: nowrap;
    width: 500px;
    height: 5;
    position: relative;
}

.sectionrotateBack {
    transform: rotate(-90deg) translate(0, 0%);
}

.gallSldmImgItm {
    width: 20%;
    float: right;
}

.gallSldMainImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.IN_GallBlock {
    position: absolute;
    z-index: 1500;
    padding: 1%;
}

.IN_sectionBlock {
    padding: 2% 1%;
    position: relative;
    z-index: 500;
}

.IN_sectionBlock.menuBtn {
    top: 30%;
}
.sliderBk {
    position: absolute;
    width: 100%;
    height: 80%;
}

.sectionBack {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 499;
}

.mainHead {
    font-size: 80px;
}

.blocksBtns {
    margin: 0 auto;
    display: table;
    margin: 20px auto;
}

.blocksBtns {}

.iamBtn:hover {
    background-color: #9a9a95 !important;
    transition: 0.7s;
}

.btnRight button,
.btnLeft button {
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    margin: 20px;
    border: 1px solid;
}

.WebPage {
    direction: rtl;
    width: 90%;
    margin: 0 auto;
    max-width: var(--theMax);
}

.socialIco {
    width: 25px;
    height: 25px;
}

.socialIco img {
    height: 100%;
    /* width: 50px; */
    object-fit: contain;
    margin: 10px;
}

.logoTop {
    position: relative;
    margin: 0 auto;
    text-align: center;
}

img.mainLogo {
    width: 50%;
    /* height: 75%; */
    object-fit: contain;
}

.logoTop,
.socialMds {
    width: 10%;
}

ul.menuStrip,
ul.socialMds {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    margin-left: 0px;

}

.topStrip li,
.socialMds li {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-end: 20px;
    padding-inline-start: 20px;
    margin-left: 2%;

}

.erMsg {
    color: red;
    font-weight: bold;
    font-size: 18px;
    direction: rtl;
}

.noSpace {
    margin: 0;
    margin-block: 0;
    font-size: 17px;
}

.sendMsgBtn {
    padding: 5px;
    min-width: 180px;
    border: none;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.sendMsgBtn:hover {
    cursor: pointer;
    opacity: 0.8;
    transition-duration: 1ms;
}

.contactInputsDiv {
    direction: rtl;
}

.contactInput {
    padding: 5px;
    width: 55%;
    border: none;
    font-size: 0.8rem;
    border-radius: 5px;
    text-align: right;
    margin-top: 5px;
    margin-bottom: 2px;
    max-width: 275px;
}

.whiteBack .contactInput {
    background-color: black;
    color: white;
    border: none;
    border-radius: unset;
    line-height: 1.5rem;
    width: 99%;
    text-align: right;

}

.whiteBack .remarksInput {
    background-color: black;
    color: white;
    border: none;
    border-radius: unset;
    line-height: 1.5rem;
    width: 100%;
    text-align: right;
    margin: 10px 0px;
}

.smallRIght {}

.whiteBack .contactInput::placeholder,
.whiteBack .remarksInput::placeholder {
    color: aliceblue;
}

.inputsDropDownStyle,
.inputsDropDownStyle option {
    border: none;
    background-color: black;
    color: white;
    margin: 2px;
    padding: 5px;
    min-width: 100px;
}

.react-datepicker-popper {
    position: relative;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker,
.react-datepicker__day-names,
.react-datepicker__current-month,
.react-datepicker__header {
    background-color: black;
    /* Change this color to your desired background color */
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    /* border-bottom-color: black; */
    display: none;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    /* border-bottom-color: black; */
    display: none;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
    color: white;
}

.react-datepicker__day--outside-month {
    color: #989ca1;
}

.react-datepicker__day:hover {
    background-color: white;
    color: black;
}

.react-datepicker__day--keyboard-selected {
    background-color: #2a87d0;
}

.popDiagBlock {
    direction: rtl;
    text-align: right;
}

.popDiagBlock .theTitlePop {
    color: white;
    font-size: 0.7rem;
}

.theTitlePop {
    margin-top: 15px !important;
    text-align: center;
}

.theTitlePop,
.theTitlePop span {
    line-height: 1.3 !important;
    font-size: 100% !important;
}

.popDiagBlock .MuiPaper-root {
    background-color: rgba(0, 0, 0, .4) !important;
    width: 80%;
    height: 60%;
    text-align: right;

}

.whiteBack .MuiPaper-root {
    background-color: rgba(255, 255, 255, 0.9) !important;
    width: 80%;
    height: 450px;
    text-align: right;

}

.whiteBack {
    direction: rtl;
    text-align: right;
    z-index: 9999 !important;
}

.whiteBack .theTitlePop {
    color: black;
    font-size: 0.7rem;
}

.whiteBack .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: unset !important;

}

.whiteBack .popUpCloseBtn {
    font-size: 30px !important;
    top: 15px;
    right: 15px;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
}

.whiteBack .btnPopRegular {
    background-color: rgb(0, 0, 0);
}

.whiteBack .inputsStyle {
    background-color: black;
    color: white;
    border: none;
    line-height: 2rem;
    padding-right: 10px;
    width: 135px;
    text-align: center;
    padding-left: 30px;
}

.divBlock {
    overflow: hidden;
}

.menuPopBlock {
    z-index: 99999 !important;
}

.menuPopBlock .MuiDialog-container .MuiPaper-root {
    max-width: unset;
    width: 95%;
    height: 100%;
    z-index: 10001;
    margin: unset;
}

.drpArrowWhite {
    width: 18px;
    height: 8px;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 12px;
    left: 10px;

}

.orderPlaceBlock {
    background-color: transparent;
    text-align: center;
    margin-top: 30px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

}

.orderPlaceBlockWrap {
    display: inline-block;
    margin: 0 auto;
}

.orderPlaceBlockWrap.ord2 {
    width: 78%;
    margin-right: calc(11% + 3px);
}

.txConInput {
    width: 50%;
}


.timesAndDtBLock {
    width: 300px;
    margin: 0 auto;
    overflow: hidden;
}

.dinersNum {
    width: 50px;
    border-radius: 5px;
    font-size: 1rem;
    padding: none;
    height: 30px;
}

.hoursSelect {
    width: 100px;
    border-radius: 5px;
    font-size: 1rem;
    height: 30px;
}

.dateForTable {
    width: 150px;
    border-radius: 5px;
    font-size: 1rem;
    padding: none;
    height: 30px;
    width: 235px;
}

.mydtPicker {
    float: right;
    position: relative;
}


.mydtPicker .react-datepicker-popper {
    display: table;
    left: -6% !important;
    inset: -14px -10% !important;
    position: fixed !important;
    left: 10%;
    font-size: 1.5rem;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 77% !important;
}

.btnsPopWrap {
    width: 70%;
    margin: 0 auto;
}

.btnPopRegular {
    background-color: rgb(93 89 89);
    color: rgb(255, 255, 255);
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px;
    width: 48%;
    text-align: center;
    line-height: 15px;
    border: none;
    font-weight: bold;
    font-size: 15px;
    bottom: 15px;
    left: 23%;
}

.btnPopRegular:hover {
    background-color: #9a9a95 !important;
    transition: 0.7s;
}


.btnPopRegular.menuBtn {
    background-color: black;
    color: #fff;
    margin: 0 auto;
    border-radius: 10px;
    border: 2px white solid;
    padding: 5px;
    width: 48%;
    text-align: center;
    line-height: 15px;
    /* border: none; */
    font-weight: 700;
    font-size: 15px;
    bottom: 15px;
    left: 23%;
}

.btnPopRegular.menuBtn:hover {
    background-color: #9a9a95 !important;
    transition: 0.7s;
}

.titleBig {
    font-size: 25px !important;
}


.imgblok {
    background-position: center;
    background-size: 80% 100%;
    background-repeat: no-repeat;
    padding-top: 65%;
    width: 100%;
    margin: 0 auto;
}

.approveMsgPopup {
    font-family: var(--MyFont) !important;
    z-index: 10000 !important;
}

.approveMsgPopup .theTitlePop {

    font-size: var(--anakMeodMeod) !important;
}

.approveMsgPopup .theTitlePop,
.approveMsgPopup .theSpanPop {
    color: black !important;
}

.approveMsgPopup.popDiagBlock .MuiPaper-root {
    background-color: white !important;
    height: unset !important;
}

.approveMsgPopup .theBottomPop {
    margin-top: 15px !important;
    text-align: center;
}

.approveMsgPopup .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
    font-family: var(--MyFont) !important;
    font-size: var(--bigFont) !important;
    text-align: center;
    line-height: 1.2 !important;
}

.WebByAsh {

    bottom: 10px;
    position: absolute;
    width: 100%;
    text-align: center;
    color: #fff;

}

.WebByAsh a {
    text-decoration: underline;
    color: white !important;
}

.WebByAsh p {
    display: table;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.8) !important;
    padding: 0px 5px;
}