@media only screen and (max-width: 800px) {

    .mobiLineHead {
        width: 60%;
        margin: 0 auto;
    }

    .mobiLineHeadTxt {
        color: white;
        font-size: 18px;
        text-align: center;
        line-height: 55px;
    }

    .mobiFloatLeft {
        color: white;
        position: fixed;
        width: 33px;
        left: 20px;
        /* width: 18px; */
        padding: 3px;
        /* line-height: 41px; */
        /* height: 1rem; */
        background-color: "black";
        border: 1px solid white;
        border-radius: 5px;
        /* border-bottom-right-radius: 5px; */
        overflow: hidden;

        /* box-shadow: 0px 1px 3px 0px #000000; */
        top: 10px;
    }

    .mobiFloatLShow {
        right: 20% !important;
        transition: right 0.5s;

        z-index: 1500;
    }


    .mobiMnuTop {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        z-index: 5000;
        width: 100%;
        height: 55px;
    }

    .logoTop {
        width: 18%;
        margin-top: 10px;
    }

    .sectionBlock {
        width: 80% !important;
        /* height: 80% !important; */
        top: 80px !important;
        left: 10% !important;
        /* position: absolute; */
        background-color: unset;
    }

    .IN_sectionBlock {
        /* background-color: rgba(0, 0, 0, 0.4); */

    }

    .btnRight button,
    .btnLeft button {
        border-radius: 5px;
        padding: 10px 15px;
        margin: 5px;
        border: 1px solid;
        font-size: 13px !important;
    }

    .smalltxt {
        padding-right: 8px;
        padding-left: 8px;
        padding-bottom: 8px;
    }

    .mainHead {
        font-size: 45px;
    }


    .swiper-slide {
        text-align: center;
        font-size: 1rem;
    }

    span {
        font-size: 1rem !important;
    }

    .mnusWrap {
        position: fixed;
        top: 50px;
        right: 100%;
        transition: right 0.5s;
        width: 80%;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mnusWrap ul {
        width: 100%;
        overflow: hidden;
        line-height: 1;
        font-size: 40px !important;
    }

    .mnusWrap li {
        text-align: center;
    }

    .menuStrip {
        /* padding-top: 50px; */
    }

    .socialMds {
        height: 50px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .socialMdsMobiWrap {
        margin: 0 auto;
        display: table;
    }

    .socialIco img {
        margin: unset;
    }


    .GallMobiBlock {
        width: 48%;
        height: 100px;
        background-position: center;
        background-size: cover;

        margin: 1%;
        float: right;
    }

    .popUpCloseBtn {
        font-size: 30px !important;
        top: 0px;
        right: 15px;
        position: absolute !important;
        color: rgba(255, 255, 255, 0.87);
    }

    .whiteBack .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        margin: 0 5px !important;
    }

    .whiteBack .MuiPaper-root {
        background-color: rgba(255, 255, 255, 0.9) !important;
        width: 100%;
        height: 80%;
        text-align: right;
    }

    .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
        padding: unset;
    }

    .whiteBack .inputsStyle {
        width: 100%;
        margin-top: 10px;
    }



    .drpArrowWhite {
        width: 18px;
        height: 8px;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 25px;
        left: 10px;
    }

    .mydtPicker {
        float: unset;
        width: 100%;
    }

    .mydtPicker .react-datepicker-wrapper {
        width: 100% !important;
    }

    .mydtPicker .react-datepicker {
        /* font-size: 1.2em;
        position: fixed;
        left: 0; */
    }

    .whiteBack .contactInput {
        margin: 10px auto 0px auto;
        width: 90%;
    }

    .whiteBack .remarksInput {
        width: 90%;
        text-align: right;
        margin: 10px 0px;
    }

    .inputsDropDownStyle,
    .inputsDropDownStyle option {

        min-width: 185px;
    }

    .mydtPicker .react-datepicker-popper {

        display: table;
        left: -6% !important;
        inset: -5px -8% !important;
        position: fixed !important;
        left: 10%;
        font-size: 1.5rem;
    }

    .pMssge {
        height: auto;
        word-wrap: break-word;
    }

    .mobiDropSelectonOpts {
        position: fixed;
        top: 70px;
        background-color: rgba(0, 0, 0, 0.9);
        color: white;
        /* font-weight: bold; */
        width: 80%;
        right: 10%;
        bottom: 10px;
        overflow: auto;
        z-index: 1;
    }

    .mobiDropSelectonOpts .fullRow {
        /* margin-bottom: 10px; */
        line-height: 2.5;
        border-bottom: 1px solid lightgrey;
    }

    .orderPlaceBlockWrap {
        width: 100%;
        margin: 0 auto;
    }
    .spBlockDrp {
        width: 33%;
        float: right;
    }
    .whiteBack .btnPopRegular {
        border-radius: 20px;
        width: 100%;
    }

    .approveMsgPopup.popDiagBlock .MuiPaper-root {
        width: 95%;
    }

    .approveMsgPopup .theTitlePop {

        font-size: var(--huge) !important;
    }

    .approveMsgPopup .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
        font-family: var(--MyFont) !important;
        font-size: var(--bigFont) !important;
        text-align: center;
        line-height: 1 !important;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        padding: unset !important;
        line-height: 2 !important;
        min-height: unset !important;
    }

    .css-69z67c-MuiTabs-scroller {
        height: 30px;
    }

    .css-1ujnqem-MuiTabs-root {
        min-height: 30px;

    }

    .fxPosWrap {
        position: fixed;
        min-height: 50px;
        background-color: white;
        width: 90%;
        border-bottom: 1px solid #000;
        right: 5%;
    }

    .divHead {
        color: white;
        margin-top: 0px;
    }

}